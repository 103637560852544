import React, {useState} from "react";
import {Chip, Icon, IconButton, Toggle, Popover, Select, TableCell, Text, TextField, Tooltip} from "@nike/eds";
import {Link, useNavigate} from "react-router-dom";

const GithubVersionOverviewTableRow = ({searchResult, envHeaders, hiddenCols}) => {

    const [isPopoverVisible, setIsPopoverVisible] = React.useState(false);
    const navigate = useNavigate();
    const [base, setBase] = useState("");
    const [to, setTo] = useState("");
    let item = {
        environment: searchResult.sortKey.split("#")[0],
        instance: searchResult.sortKey.split("#")[1]
    };
    const versions = [
        ...new Map([
            ...searchResult.dev?.map(item => [item.version, {
                value: item.version,
                label: item.version,
                githubCommitSha: item.githubCommitSha
            }]) || [],
            ...searchResult.test?.map(item => [item.version, {
                value: item.version,
                label: item.version,
                githubCommitSha: item.githubCommitSha
            }]) || [],
            ...searchResult.prod?.map(item => [item.version, {
                value: item.version,
                label: item.version,
                githubCommitSha: item.githubCommitSha
            }]) || [],
        ]).values()
    ];
    versions.push({value: "main", label: "main", githubCommitSha: "main"})
    console.log(versions);
    // @ts-ignore
    return (
        <tr>
            <TableCell className="eds-spacing--p-12">
                <div className="eds-grid eds-gap--8 eds-grid--s-cols-1">
                    <a><Text font={"title-5"} onClick={() => window.open("https://github.com/" + searchResult.githubRepo, "_blank")}>{searchResult.sortKey.split("#")[4]}</Text></a>
                    { searchResult.sortKey.split("#")[3] !== "none" &&
                        <Text font={"body-2"}>{searchResult.sortKey.split("#")[3]}</Text>
                    }
                    <Text font={"body-2"}>{searchResult.sortKey.split("#")[2]}</Text>
                    <Text font={"body-3"}>{searchResult.sortKey.split("#")[0]}-{searchResult.sortKey.split("#")[1]}</Text>

                </div>
            </TableCell>

            {envHeaders.map(header =>
                <TableCell className="eds-spacing--p-12" key={header.Header}>
                    {!hiddenCols.includes(header.Header) && (
                        <div className="eds-grid eds-gap--8 eds-grid--s-cols-2">
                    {Object.entries(searchResult.envs[header.Header] || {}).map(([innerkey, innerValue]) => (
                        <Tooltip bodySlot={// @ts-ignore
                             innerValue.version} isDark key={innerkey}>
                            <div className="chip-container">
                                <Text font={"body-3"}>{// @ts-ignore
                                     innerValue.region}:</Text>
                                <Chip size="small" as="button"
                                    // @ts-ignore
                                      onClick={() => window.open("https://github.com/" + searchResult.githubRepo + "/commit/" + innerValue.githubCommitSha, "_blank")}
                                      beforeSlot={<Icon name="Bookmark"/>}>
                                    {// @ts-ignore
                                         innerValue.version }
                                </Chip>
                            </div>
                        </Tooltip>
                    ))}
                    </div>
                    )}
                </TableCell>)}

            <TableCell className="eds-spacing--p-12">
                { !hiddenCols.includes("Compare") && (
                    <>
                        <Select
                            hideLabel={true}
                            isCreatable={true}
                            id="compare"
                            label={"compare 1"}
                            options={versions}
                            onChange={(event) => { // @ts-ignore
                                setBase(event.githubCommitSha); }}
                        />
                        <IconButton
                            variant="primary"
                            size={'small'}
                            icon="Compare"
                            label={''}
                            onClick={() => window.open("https://github.com/" + searchResult.githubRepo + "/compare/" + base + "..." + to, "_blank")}
                            disabled={!base || !to}
                        />
                        <Select
                            id="compareTo"
                            hideLabel={true}
                            isCreatable={true}
                            label={"compare 2"}
                            options={versions}
                            onChange={(event) => { // @ts-ignore
                                setTo(event.githubCommitSha); }}
                        />
                    </>
                )}
            </TableCell>
        </tr>
    );
}

function VersionOverviewTableRow({searchResult, envHeaders, hiddenCols}) {

    return (
        <GithubVersionOverviewTableRow searchResult={searchResult} envHeaders={envHeaders} hiddenCols={hiddenCols}/>

    );
}

export {VersionOverviewTableRow};
