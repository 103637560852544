import { AppHeader, Avatar, IconButton } from '@nike/eds'
import { NikeApp } from '@nike/nike-design-system-icons'
import { useOktaAuth } from '@okta/okta-react'
import { Link } from 'react-router-dom'
import { useMemo } from 'react'

export function Header() {
    const { authState } = useOktaAuth()
    const initials = useMemo(() => {
        const name = authState?.accessToken?.claims?.sub
        return name ? getInitials(name) : '-'
    }, [authState])

    return (
        <AppHeader
            isDark
            appName='VERSION MANAGEMENT'
            className="futura-app-header"
            logoSlot={
                <Link reloadDocument to='/' aria-label='logo-link'>
                    <NikeApp
                        title='Nike logo'
                        color={'orange'}
                        width={'56px'}
                        height={'64px'}
                        style={{marginTop: '6px'}}
                    />
                </Link>
            }
            toolbarSlot={
                <div>
                    <IconButton as={Link} to='/version-overview' label='Version overview' icon='Whiteboard' variant='ghost' />
                </div>
            }
            avatarSlot={<Avatar initials={initials} size={36} />}
        />
    )
}

function getInitials(name: string) {
    const matchedFirstLetters = name.match(/(\b[A-Z])/g)
    if (!matchedFirstLetters || matchedFirstLetters.length < 2) {
        // Fallback: take first letter
        return name[0]?.toUpperCase()
    }
    return `${matchedFirstLetters.shift()}${matchedFirstLetters.pop()}`
}
