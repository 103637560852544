import React, { useEffect, useState } from 'react'
import { Card, IconButton, Select } from '@nike/eds'
import { RequestedSearchForm } from '../../model/RequestSearchForm'
import { VersionManagementService } from '../services/VersionManagementService'
import { AppsyncService } from '../services/AppsyncService'
import { oktaAuth } from '../../config/OktaConfig'
import { useSearchParams } from 'react-router-dom'

const appsyncService: AppsyncService = new AppsyncService(oktaAuth)
const versionManagementService: VersionManagementService = new VersionManagementService(appsyncService)

interface SearchCriteria {
    category: string
    subCategory: string
    snowService: string
    instance: string
}

const emptySearchCriteria = () => {
    return {
        category: '',
        subCategory: '',
        snowService: '',
        instance: '',
    }
}

export function OverviewSearchForm({ onSearch }) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchCriteria, setNewSearchCriteria] = useState<SearchCriteria>(emptySearchCriteria)

    const [instanceFilterVisible, setInstanceFilterVisible] = useState(false)
    const [filteredSubCategories, setFilteredSubCategories] = useState([])
    const [filteredSnowServices, setFilteredSnowServices] = useState([])
    const [filteredInstances, setFilteredInstances] = useState([])
    const [retrievedSearchCriteria, setRetrievedSearchCriteria] = useState([])
    const [uniqueCategories, setUniqueCategories] = useState(new Set())

    useEffect(() => {
        versionManagementService.getSearchEntries().then((result) => {
            const categories = new Set(result[0].searchEntries.map((param) => param.category))
            setUniqueCategories(categories)

            setRetrievedSearchCriteria(result[0].searchEntries)
        })
    }, [])

    useEffect(() => {
        let newSearchParams = {
            ...(searchParams.has('category') && { category: searchParams.get('category') }),
            ...(searchParams.has('subCategory') && { subCategory: searchParams.get('subCategory') }),
            ...(searchParams.has('snowService') && { snowService: searchParams.get('snowService') }),
            ...(searchParams.has('instance') && { instance: searchParams.get('instance') }),
        }
        setSearchCriteria(newSearchParams, true)
    }, [searchParams])

    useEffect(() => {
        if (searchCriteria.category && searchCriteria.subCategory) {
            onSearch(searchCriteria)
        }
    }, [searchCriteria])

    useEffect(() => {
        if (retrievedSearchCriteria.length && searchCriteria.category) {
            updateCategory(searchCriteria.category)
            updateSubCategory(searchCriteria.subCategory)
            updateSnowService(searchCriteria.snowService)
        }
    }, [retrievedSearchCriteria])

    const setSearchCriteria = (newSearchCriteria: SearchCriteria, fromSearchParams: boolean = false) => {
        setNewSearchCriteria(newSearchCriteria)
        let newSearchParams = {
            ...(newSearchCriteria.category && { category: newSearchCriteria.category }),
            ...(newSearchCriteria.subCategory && { subCategory: newSearchCriteria.subCategory }),
            ...(newSearchCriteria.snowService && { snowService: newSearchCriteria.snowService }),
            ...(newSearchCriteria.instance && { instance: newSearchCriteria.instance }),
        }
        if (!fromSearchParams) {
            debugger
            setSearchParams(newSearchParams)
        }
    }

    const categoryChangeHandler = (event) => {
        const selectedCategory = event ? event.value : ''
        setSearchCriteria({
            ...searchCriteria,
            category: selectedCategory,
            subCategory: '',
            snowService: '',
            instance: '',
        })
        updateCategory(selectedCategory)
    }

    const updateCategory = (selectedCategory: string) => {
        const uniqueSubCategories = new Set(retrievedSearchCriteria.filter((param) => param.category === selectedCategory).map((param) => param.subCategory))
        setFilteredSubCategories(Array.from(uniqueSubCategories).map((subCategory) => ({ label: subCategory, value: subCategory })))
        setFilteredSnowServices([])
        setFilteredInstances([])
        setInstanceFilterVisible(false)
    }

    const subCategoryChangeHandler = (event) => {
        const selectedSubCategory = event ? event.value : ''
        setSearchCriteria({
            ...searchCriteria,
            subCategory: selectedSubCategory,
            snowService: '',
            instance: '',
        })
        updateSubCategory(selectedSubCategory)
    }

    const updateSubCategory = (selectedSubCategory: string) => {
        const uniqueSnowServices = new Set(retrievedSearchCriteria.filter((param) => param.subCategory === selectedSubCategory).map((param) => param.snowService))
        setFilteredInstances([])
        setFilteredSnowServices(Array.from(uniqueSnowServices).map((snowService) => ({ label: snowService, value: snowService })))
        setInstanceFilterVisible(false)
    }

    const snowServiceChangeHandler = (event) => {
        const selectedSnowService = event ? event.value : ''
        setSearchCriteria({
            ...searchCriteria,
            snowService: selectedSnowService,
            instance: '',
        })
        updateSnowService(selectedSnowService)
    }

    const updateSnowService = (selectedSnowService: string) => {
        const uniqueInstances = new Set(retrievedSearchCriteria.filter((param) => param.snowService === selectedSnowService).map((param) => param.instance))
        setFilteredInstances(Array.from(uniqueInstances).map((instance) => ({ label: instance, value: instance })))
        setInstanceFilterVisible(!(uniqueInstances.size == 1 && uniqueInstances.has('none')))
    }

    const instanceChangeHandler = (event) => {
        const selectedInstance = event ? event.value : ''
        setSearchCriteria({
            ...searchCriteria,
            instance: selectedInstance,
        })
    }

    const doSearch = (restore = false) => {
        let searchRequestData: RequestedSearchForm = {
            category: searchCriteria.category,
            subCategory: searchCriteria.subCategory,
            snowService: searchCriteria.snowService,
            instance: searchCriteria.instance,
        }

        onSearch(searchRequestData, restore)
    }

    const submitHandler = (event) => {
        event.preventDefault()
        doSearch()
    }

    const clearSearchCriteria = () => {
        setFilteredSubCategories([])
        setFilteredSnowServices([])
        searchCriteria.category = ''
        searchCriteria.subCategory = ''
        searchCriteria.snowService = ''
        searchCriteria.instance = ''
        doSearch(true)
    }

    return (
        <Card>
            <form onSubmit={submitHandler}>
                <div className="eds-grid eds-grid--m-cols-5 eds-gap--16">
                    <Select
                        isClearable={true}
                        isCreatable={true}
                        id="category"
                        value={searchCriteria?.category ? { label: searchCriteria.category, value: searchCriteria.category } : null}
                        // @ts-ignore
                        options={Array.from(uniqueCategories).map((category) => ({ label: category, value: category }))}
                        label="Category"
                        onChange={categoryChangeHandler}
                    />
                    <Select
                        isClearable={true}
                        isCreatable={true}
                        id="subCategory"
                        value={searchCriteria?.subCategory ? { label: searchCriteria.subCategory, value: searchCriteria.subCategory } : null}
                        options={filteredSubCategories}
                        label="Sub Category"
                        onChange={subCategoryChangeHandler}
                        isDisabled={!searchCriteria.category}
                    />
                    <Select
                        isClearable={true}
                        isCreatable={true}
                        id="snowService"
                        value={searchCriteria?.snowService ? { label: searchCriteria.snowService, value: searchCriteria.snowService } : null}
                        options={filteredSnowServices}
                        label="Snow Service"
                        onChange={snowServiceChangeHandler}
                        isDisabled={!searchCriteria.subCategory}
                    />
                    {instanceFilterVisible && (
                        <Select
                            isClearable={true}
                            isCreatable={true}
                            id="instance"
                            value={searchCriteria?.instance ? { label: searchCriteria.instance, value: searchCriteria.instance } : null}
                            options={filteredInstances}
                            label="Instance"
                            onChange={instanceChangeHandler}
                            isDisabled={!searchCriteria.snowService}
                        />
                    )}

                    <div className="eds-spacing--p-4 eds-grid--item-justify-end" style={{ display: 'flex' }}>
                        <div className="eds-spacing--p-4">
                            <IconButton variant="primary" type={'submit'} size={'small'} icon="Search" label={''} />
                        </div>
                        <div className="eds-spacing--p-4">
                            <IconButton onClick={clearSearchCriteria} variant="primary" icon="Undo" size={'small'} label={''} />
                        </div>
                    </div>
                </div>
            </form>
        </Card>
    )
}
