import React, {useState} from "react";
import {Table, TableHeading, TableCell, Skeleton, Toggle, Icon} from "@nike/eds";
import {VersionOverviewTableRow} from "./VersionOverviewTableRow";

function VersionOverviewTable(props) {
    let envHeaders = [];
    const [hiddenCols, sethiddenCols] = useState(["Compare"]);

    if (props.searchResults.length !== 0) {
        let allKeys = new Set();
        props.searchResults.forEach((searchResult) => {
            Object.keys(searchResult.envs).forEach((key) => {
                allKeys.add(key);
            });
        });
        let sortedKeys = Array.from(allKeys).sort((a, b) => {
            if (a === "dev") return -1;
            if (b === "prod") return -1;
            if (a === "prod") return 1;
            if (b === "dev") return 1;
            return 0;
        });
        envHeaders = sortedKeys.map(env => ({Header: env}));


        props.searchResults.forEach((searchResult) => {
            sortedKeys.forEach((key) => {
                // @ts-ignore
                if (!searchResult.envs[key]) {
                    // @ts-ignore
                    searchResult.envs[key] = [];
                }
            });
        });

    }
    const headers = [
        {Header: "Category"},
        ...envHeaders,
        {Header: "Compare"}
    ];


    return (
        <div className="eds-grid eds-grid--s-cols-6">
            <Table className="eds-grid--s-col-6">
                <thead>
                <tr>
                    {headers.map(column => (<TableHeading className="eds-spacing--p-12"
                                                          key={column.Header}> <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: "8px" }}>{column.Header}</span> {column.Header !== "Category" &&
                        <Icon
                            name={hiddenCols.includes(column.Header) ? "Show" : "Hide"}
                            onClick={() => {
                               if (hiddenCols.includes(column.Header)){
                                    sethiddenCols(hiddenCols.filter((col) => col !== column.Header))
                                  } else {
                                sethiddenCols([...hiddenCols, column.Header])
                            }}}
                        />
                    }</div>

                    </TableHeading>))}
                </tr>
                </thead>
                <tbody>
                {props.loading &&
                    <>
                        <tr>
                            <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                        </tr>
                    </>
                }
                {!props.loading && props.searchResults.map(searchResult => (
                    <VersionOverviewTableRow key={searchResult.hashKey + "#" + searchResult.sortKey}
                                             searchResult={searchResult} envHeaders={envHeaders} hiddenCols={hiddenCols}/>

                ))}
                </tbody>
            </Table>
        </div>
    );
}

export {VersionOverviewTable};
