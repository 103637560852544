import React, {useEffect, useState} from 'react'
import {Card, IconButton, Select} from '@nike/eds'
import useSessionStorage from '../../hooks/useSessionStorage'
import {RequestedSearchForm} from '../../model/RequestSearchForm'
import { VersionManagementService } from '../services/VersionManagementService'
import {AppsyncService} from "../services/AppsyncService";
import {oktaAuth} from "../../config/OktaConfig";
const appsyncService: AppsyncService = new AppsyncService(oktaAuth)
const versionManagementService: VersionManagementService = new VersionManagementService(appsyncService)
const emptySearchCriteria = () => {
    return {
        category: '',
        subCategory: '',
        snowService: '',
        instance: '',
    }
}

export function OverviewSearchForm({onSearch}) {
    const [searchCriteriaState, setSearchCriteria] = useSessionStorage('searchCriteria', emptySearchCriteria())
    const searchCriteria = searchCriteriaState.value

    const [instanceFilterVisible, setInstanceFilterVisible] = useState(false)
    const [filteredSubCategories, setFilteredSubCategories] = useState([])
    const [filteredSnowServices, setFilteredSnowServices] = useState([])
    const [filteredInstances, setFilteredInstances] = useState([])
    const [searchParameters, setSearchParameters] = useState([])
    const [uniqueCategories, setUniqueCategories] = useState(new Set());

    //let searchParameters = []
    useEffect(() => {
            versionManagementService.getSearchEntries().then((result) => {
                const categories = new Set(result[0].searchEntries.map(param => param.category));
                setUniqueCategories(categories);
                setSearchParameters(result[0].searchEntries);
            });
    }, []);

    const categoryChangeHandler = (event) => {
        const selectedCategory = event ? event.value : ''
        setSearchCriteria({
            ...searchCriteria,
            category: selectedCategory,
            subCategory: '',
            snowService: '',
            instance: ''
        })
        const uniqueSubCategories = new Set(
            searchParameters
                .filter(param => param.category === selectedCategory)
                .map(param => param.subCategory)
        );
        setFilteredSubCategories(Array.from(uniqueSubCategories).map(subCategory => ({ label: subCategory, value: subCategory })));
        setFilteredSnowServices([])
        setFilteredInstances([])
        setInstanceFilterVisible(false)
    }
    const subCategoryChangeHandler = (event) => {
        const selectedSubCategory = event ? event.value : ''
            setSearchCriteria({
                ...searchCriteria,
                subCategory: selectedSubCategory,
                snowService: '',
                instance: ''

            })
        const uniqueSnowServices = new Set(
            searchParameters
                .filter(param => param.subCategory === selectedSubCategory)
                .map(param => param.snowService)
        );
        setFilteredInstances([])
        setFilteredSnowServices(
            Array.from(uniqueSnowServices).map(snowService => ({ label: snowService, value: snowService }))
        );
        setInstanceFilterVisible(false)
    }
    const snowServiceChangeHandler = (event) => {
        const selectedSnowService = event ? event.value : ''
            setSearchCriteria({
                ...searchCriteria,
                snowService: selectedSnowService,
                instance: ''
            })
        const uniqueInstances = new Set(
            searchParameters
                .filter(param => param.snowService === selectedSnowService)
                .map(param => param.instance)
        );
        setFilteredInstances(
            Array.from(uniqueInstances).map(instance => ({ label: instance, value: instance }))
        );
        setInstanceFilterVisible(!(uniqueInstances.size == 1 && uniqueInstances.has('none')))
    }
    const instanceChangeHandler = (event) => {
        const selectedInstance = event ? event.value : ''
        setSearchCriteria({
            ...searchCriteria,
            instance: selectedInstance,
        })
    }



    const doSearch = (restore = false) => {
        let searchRequestData: RequestedSearchForm = {
            category: searchCriteria.category,
            subCategory: searchCriteria.subCategory,
            snowService: searchCriteria.snowService,
            instance: searchCriteria.instance,
        }
        onSearch(searchRequestData, restore);
    }

    const submitHandler = (event) => {
        event.preventDefault()
        doSearch()
    }

    const clearSearchCriteria = () => {
        setFilteredSubCategories([])
        setFilteredSnowServices([])
        searchCriteria.snowService = ''
        searchCriteria.subCategory = ''
        searchCriteria.category = ''
        doSearch(true);
    }

    useEffect(() => {
        if (searchCriteriaState.restored) {
            doSearch(true);
        }
    }, [searchCriteriaState.restored]);



    return (
        <div className="eds-spacing--ml-20 eds-spacing--mr-20 eds-spacing--mb-20">
            <form onSubmit={submitHandler}>
                <Card className="eds-grid eds-grid--m-cols-5 eds-gap--8 eds-elevation--4">
                    <Select
                        isClearable={true}
                        isCreatable={true}
                        id="category"
                        value={searchCriteria.category ? { label: searchCriteria.category, value: searchCriteria.category } : null}
                        // @ts-ignore
                        options={Array.from(uniqueCategories).map(category => ({ label: category, value: category }))}
                        label="Category"
                        onChange={categoryChangeHandler}
                    />
                    <Select
                        isClearable={true}
                        isCreatable={true}
                        id="subCategory"
                        value={searchCriteria.subCategory ? { label: searchCriteria.subCategory, value: searchCriteria.subCategory } : null}
                        options={filteredSubCategories}
                        label="Sub Category"
                        onChange={subCategoryChangeHandler}
                        isDisabled={!searchCriteria.category}
                    />
                    <Select
                        isClearable={true}
                        isCreatable={true}
                        id="snowService"
                        value={searchCriteria.snowService ? { label: searchCriteria.snowService, value: searchCriteria.snowService } : null}
                        options={filteredSnowServices}
                        label="Snow Service"
                        onChange={snowServiceChangeHandler}
                        isDisabled={!searchCriteria.subCategory}
                    />
                    { instanceFilterVisible &&
                        <Select
                            isClearable={true}
                            isCreatable={true}
                            id="instance"
                            value={searchCriteria.instance ? { label: searchCriteria.instance, value: searchCriteria.instance } : null}
                            options={filteredInstances}
                            label="Instance"
                            onChange={instanceChangeHandler}
                            isDisabled={!searchCriteria.snowService}
                        />
                    }


                    <div className="eds-spacing--p-4 eds-grid--item-justify-end" style={{"display": "flex"}}>
                        <div className="eds-spacing--p-4">
                            <IconButton
                                variant="primary"
                                type={'submit'}
                                size={'small'}
                                icon="Search"
                                label={''}/>
                        </div>
                        <div className="eds-spacing--p-4">
                            <IconButton
                                onClick={clearSearchCriteria}
                                variant="primary"
                                icon="Undo"
                                size={'small'}
                                label={''}/>
                        </div>
                    </div>
                </Card>
            </form>
        </div>
    )
}
