import {OktaAuth} from '@okta/okta-auth-js'

const oktaConfig = {
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    redirectUri: `${window.location.origin}/implicit/callback`,
    clientId: 'nike.niketech.version-management-ui',
    scopes: ['openid'],
    pkce: true,
}

export const oktaAuth = new OktaAuth(oktaConfig)
