import {AppsyncService} from './AppsyncService'
import gql from "graphql-tag";

import {
    list, listByCategory, listBySortKey
} from '../../graphql/queries'

export class VersionManagementService {

    constructor(private appsyncService: AppsyncService) {
    }
    async getSearchEntries(): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(listBySortKey), fetchPolicy: 'network-only', variables: {
                hashKey: "SEARCH_ENTRY",
                sortKey: "SEARCH_ENTRY",
                nextToken: null,
            }
            }
        ).then((result) => {
            // @ts-ignore
            return  result.data.listBySortKey.items;
        });
    }
    async list(nextToken: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(list), fetchPolicy: 'network-only', variables: {
                    nextToken: nextToken,
                }
            }
        ).then((result) => {
            // @ts-ignore
            let items = result.data.list.items;

            const serviceInfoItems = items.filter(item => item.hashKey === 'SERVICE_INFO');
            const serviceVersionItems = items.filter(item => item.hashKey === 'SERVICE_VERSION');
            return this.CombineWithVersionInfo(serviceInfoItems, serviceVersionItems);
        });
    }

    async listBySortkey(sortKey : string, nextToken: string): Promise<any> {

        const serviceVersionPromise = this.appsyncService.appSyncClient.query({
            query: gql(listBySortKey), fetchPolicy: 'network-only', variables: {
                hashKey: "SERVICE_VERSION",
                sortKey: sortKey,
                nextToken: nextToken,
            }
        });

        const serviceInfoPromise = this.appsyncService.appSyncClient.query({
            query: gql(listBySortKey), fetchPolicy: 'network-only', variables: {
                hashKey: "SERVICE_INFO",
                sortKey: sortKey,
                nextToken: nextToken,
            }
        });

        const [serviceVersionResult, serviceInfoResult] = await Promise.all([serviceVersionPromise, serviceInfoPromise]);

        // @ts-ignore
        const serviceVersionItems = serviceVersionResult.data.listBySortKey.items;
        // @ts-ignore
        const serviceInfoItems = serviceInfoResult.data.listBySortKey.items;

        return this.CombineWithVersionInfo(serviceInfoItems, serviceVersionItems);
    }
    private CombineWithVersionInfo(serviceInfoItems, serviceVersionItems) {
        const serviceInfoItemsMap = new Map();

        serviceInfoItems.forEach(serviceInfo => {
            serviceInfoItemsMap.set(serviceInfo.sortKey, serviceInfo);
            serviceInfo.dev = [];
            serviceInfo.test = [];
            serviceInfo.prod = [];
            serviceInfo.envs = [];
        });
        serviceVersionItems.forEach(serviceVersion => {
                let splittedSortKey = serviceVersion.sortKey.split('#');
                let serviceInfoSortKey = splittedSortKey.slice(0, 5).join('#');
                let env = splittedSortKey[5];
                let version = serviceVersion.version;
                let githubCommitSha = serviceVersion.githubCommitSha;
                let region = serviceVersion.region;

                if (!serviceInfoItemsMap.get(serviceInfoSortKey)[env]) {
                    serviceInfoItemsMap.get(serviceInfoSortKey)[env] = [];
                }
                serviceInfoItemsMap.get(serviceInfoSortKey)[env].push({version, githubCommitSha, region});


                if(!serviceInfoItemsMap.get(serviceInfoSortKey).envs){
                    serviceInfoItemsMap.get(serviceInfoSortKey).envs = [];
                }
                if (!serviceInfoItemsMap.get(serviceInfoSortKey).envs[env]) {
                serviceInfoItemsMap.get(serviceInfoSortKey).envs[env] = [];
                }
                serviceInfoItemsMap.get(serviceInfoSortKey).envs[env].push({version, githubCommitSha, region});
            }
        );
        return Array.from(serviceInfoItemsMap.values());
    }
}
