/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./api";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const list = /* GraphQL */ `query List($limit: Int, $nextToken: String) {
  list(limit: $limit, nextToken: $nextToken) {
    items {
      hashKey
      sortKey
      category
      geo
      githubRepo
      region
      version
      githubCommitSha
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListQueryVariables, APITypes.ListQuery>;
export const listByCategory = /* GraphQL */ `query ListByCategory($hashKey: String!, $limit: Int, $nextToken: String) {
  listByCategory(hashKey: $hashKey, limit: $limit, nextToken: $nextToken) {
    items {
      hashKey
      sortKey
      category
      geo
      githubRepo
      region
      version
      githubCommitSha
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListByCategoryQueryVariables,
  APITypes.ListByCategoryQuery
>;
export const listBySortKey = /* GraphQL */ `query ListBySortKey(
  $hashKey: String!
  $sortKey: String!
  $limit: Int
  $nextToken: String
) {
  listBySortKey(
    hashKey: $hashKey
    sortKey: $sortKey
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      hashKey
      sortKey
      category
      geo
      githubRepo
      region
      version
      githubCommitSha
            searchEntries {
        category
        snowService
        subCategory
        instance
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBySortKeyQueryVariables,
  APITypes.ListBySortKeyQuery
>;
