import React, { useContext, useState } from 'react'
import { Header } from '../shared/header/Header'
import { Footer } from '../shared/footer/Footer'
import { oktaAuth } from '../config/OktaConfig'
import { VersionOverviewTable } from '../components/overview/VersionOverviewTable'
import { AppsyncService } from '../components/services/AppsyncService'
import { VersionManagementService } from '../components/services/VersionManagementService'
import useSessionStorage from '../hooks/useSessionStorage'
import { OverviewSearchForm } from '../components/search/OverviewSearchForm'
import { RequestedSearchForm } from '../model/RequestSearchForm'
import SnackbarContext from '../providers/snackbar-context'

const appsyncService: AppsyncService = new AppsyncService(oktaAuth)
const versionManagementService: VersionManagementService = new VersionManagementService(appsyncService)

export function VersionOverview() {
    const snackbarCtx = useContext(SnackbarContext)
    const [loading, setLoading] = useState(false)
    const [pagingTokensSession, setPagingTokens] = useSessionStorage('pagingTokens', [])
    let [searchResults, setSearchResults] = useState([])
    let pagingTokens = pagingTokensSession.value
    const [currentSearchForm, setCurrentSearchForm] = useState({})

    const searchHandler = (requestedSearchForm: RequestedSearchForm, restore: boolean) => {
        setCurrentSearchForm(requestedSearchForm)
        if (restore) {
            pagingTokens = pagingTokens.slice(0, -1)
        } else {
            pagingTokens = []
        }
        searchResults = []
        fetchData(requestedSearchForm, restore)
    }

    const fetchData = (requestedSearchForm: RequestedSearchForm, restore: boolean = false) => {
        setLoading(true)

        console.log('fetchData with: ' + JSON.stringify(requestedSearchForm))
        if (!requestedSearchForm.category) {
            console.log('scanning')
            versionManagementService
                .list(pagingTokens.at(-1))
                .then((result) => {
                    setLoading(false)
                    setSearchResults(result)
                    if (!restore) {
                        setPagingTokens(pagingTokens.concat(result.data.list.nextToken))
                    }
                })
                .catch((error) => {
                    setLoading(false)
                    snackbarCtx.displayMsg(error.message, 'error')
                })
        } else if (!!requestedSearchForm.category && !requestedSearchForm.subCategory) {
            versionManagementService
                .listBySortkey(requestedSearchForm.category + '#', pagingTokens.at(-1))
                .then((result) => {
                    setLoading(false)
                    console.log('results')
                    setSearchResults(result)
                    setPagingTokens(pagingTokens.concat(result.data.listByProject.nextToken))
                })
                .catch((error) => {
                    setLoading(false)
                    snackbarCtx.displayMsg(error.message, 'error')
                })
        } else if (!!requestedSearchForm.category && !!requestedSearchForm.subCategory && !requestedSearchForm.snowService) {
            versionManagementService
                .listBySortkey(requestedSearchForm.category + '#' + requestedSearchForm.subCategory, pagingTokens.at(-1))
                .then((result) => {
                    setLoading(false)
                    console.log('results')
                    setSearchResults(result)
                    setPagingTokens(pagingTokens.concat(result.data.listBySortKey.nextToken))
                })
                .catch((error) => {
                    setLoading(false)
                    snackbarCtx.displayMsg(error.message, 'error')
                })
        } else if (!!requestedSearchForm.category && !!requestedSearchForm.subCategory && !!requestedSearchForm.snowService && !requestedSearchForm.instance) {
            versionManagementService
                .listBySortkey(requestedSearchForm.category + '#' + requestedSearchForm.subCategory + '#' + requestedSearchForm.snowService, pagingTokens.at(-1))
                .then((result) => {
                    setLoading(false)
                    setSearchResults(result)
                    setPagingTokens(pagingTokens.concat(result.data.listBySortKey.nextToken))
                })
                .catch((error) => {
                    setLoading(false)
                    snackbarCtx.displayMsg(error.message, 'error')
                })
        } else if (!!requestedSearchForm.category && !!requestedSearchForm.subCategory && !!requestedSearchForm.snowService && !!requestedSearchForm.instance) {
            versionManagementService
                .listBySortkey(requestedSearchForm.category + '#' + requestedSearchForm.subCategory + '#' + requestedSearchForm.snowService + '#' + requestedSearchForm.instance, pagingTokens.at(-1))
                .then((result) => {
                    setLoading(false)
                    setSearchResults(result)
                    setPagingTokens(pagingTokens.concat(result.data.listBySortKey.nextToken))
                })
                .catch((error) => {
                    setLoading(false)
                    snackbarCtx.displayMsg(error.message, 'error')
                })
        }
    }

    return (
        <div>
            <Header />
            <div className="mainContentLayout eds-flex eds-flex--direction-column eds-gap--16">
                <OverviewSearchForm onSearch={searchHandler} />
                <VersionOverviewTable searchResults={searchResults} loading={loading} />
                <Footer />
            </div>
        </div>
    )
}
